
$(document).ready(function() {

    /**
     * Collpses filters by level.
     * @param int level
     * @param array exceptions
     */
    function collapseFiltersByLevel(level, exceptions){

        if (typeof exceptions === 'undefined') {
            exceptions = [];
        }

        $('[data-filter-level]').each(function(i, obj) {

            var elem = $(this);

            if(elem.attr('data-filter-level') >= level && exceptions.indexOf(elem.attr('id')) == -1){
                elem.collapse('hide');
            }

        });

    }

    $('.filters-list').on('shown.bs.collapse', function (event) {
        event.stopPropagation();
        var collapsableElement = $(this);
        var trigger = collapsableElement.closest('.filter-item').find('.filter-checkbox:first');
        trigger.addClass('open');
        // Collpase any open elements after this level
        collapseFiltersByLevel(collapsableElement.attr('data-filter-level'), [collapsableElement.attr('id')]);
    });

    $('.filters-list').on('show.bs.collapse', function (event) {
        event.stopPropagation();
        var collapsableElement = $(this);
        var trigger = collapsableElement.closest('.filter-item').find('.filter-checkbox:first');
        trigger.addClass('open');
        $('#filters-modal').animate({scrollTop: $(this).offset().top + -40}, 500);
    });

    $('.filters-list').on('hidden.bs.collapse', function (event) {
        event.stopPropagation();
        var triggerContainer = $(this).closest('.filter-item').find('.filter-checkbox:first');
        triggerContainer.removeClass('open');
        // Uncheck all checkboxs
        $(this).closest('.filter-item').find('input[type=checkbox]').prop('checked', false);
    });

    $('#filters-modal').on('hidden.bs.modal', function (e) {
        // Collpase all filters
        collapseFiltersByLevel(0);
    });

    /**
     * Redirect the User to the filter picked on the modal.
     */
    $(document).on('click', '[data-target-filter-url]', function(e){
        $('#filters-loader').removeClass('hidden');
        var href = $(this).attr('data-target-filter-url')
        setTimeout(function() {
            window.location.href = href;
        },1000);
    });

    $('main').addClass('overflowIn');

    $('.filters-dropdown').on('shown.bs.dropdown', function() {

        if($(this).hasClass("open")){

            var bodyHeight = $('body').height();

            var dropdownMenuElement = $(this).find('.dropdown-menu');
            if(dropdownMenuElement.height() > bodyHeight) {
                dropdownMenuElement.addClass("show")
                    .css({'max-height': (bodyHeight - dropdownMenuElement.offset().top) + 'px'})
                    .removeClass("show");
            }
        }

    });
});
